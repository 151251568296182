function categoriesBlockUi($root, idCat = 0, byPassCatInThePath = false, triggerClick = false) {
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory('searchEngineHandler.js');
  const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');
  const redirection = GlobalSite.checkDependency('GlobalSite.getFaqRedirection');
  const getClient = GlobalSite.checkDependency('GlobalSite.getClient');
  const answerBlockUi = GlobalSite.checkDependency('GlobalSite.answerBlockUi');

  const iconMapper = {
    2: 'i-loan',
    3: 'i-visa-card',
    69: 'i-calendar',
    4: 'i-person',
    5: 'i-book',
    54: 'i-smartphone',
    53: 'i-call-center',
    70: 'i-question-circle',
  };

  let categoryInThePath = {};

  function filterCategories(inbentaCategories) {
    return (inbentaCategories[idCat] || []).filter(
      (cat) =>
        (cat.children.length > 0 || cat.numContents > 0 || cat.numContentsDescendants > 0) &&
        cat.slug !== 'faq-b2b',
    );
  }

  function categoriesTemplate(filtredCategories) {
    return filtredCategories
      .map((cat) => {
        return `<li class="category-item ${
          location.pathname.includes(cat?.slug) && !byPassCatInThePath ? 'active' : ''
        }"
                    ${
                      idCat === 0 ? `data-cat-href="${redirection?.answer}${cat?.slug}"` : ''
                    } 
                    data-cat-id="${cat?.id}" data-name="${cat?.name}" data-slug="${cat?.slug}">
                        ${
                          idCat === 0 && getClient() === 'cetelem'
                            ? `<i class="picto ${
                                iconMapper[cat?.id]
                              }" data-cat-href="${redirection?.answer}${
                                cat?.slug
                              }" data-cat-id="${cat?.id}" data-name="${cat?.name}" data-slug="${
                                cat?.slug
                              }"></i>`
                            : ''
                        }
                        <button ${
                          idCat === 0
                            ? `data-cat-href="${redirection?.answer}${cat?.slug}"`
                            : ''
                        } 
                        data-cat-id="${cat?.id}" data-name="${cat?.name}" data-slug="${cat?.slug}">
                          <span ${
                            idCat === 0
                              ? `data-cat-href="${redirection?.answer}${cat?.slug}"`
                              : ''
                          } 
                          data-cat-id="${cat?.id}" data-name="${cat?.name}" data-slug="${
          cat?.slug
        }" class="text-cropper">
                            ${cat?.name}
                          </span>
                        </button>
                    </li>`;
      })
      .join('');
  }

  function findCategoryInThePath(filtredCategories) {
    return filtredCategories.reduce((acc, next) => {
      if (location.pathname.includes(next?.slug)) {
        Object.assign(acc, { name: next?.slug, isInPath: true });
      }
      return acc;
    }, {});
  }

  function eventInit($elems) {
    const $catLevelTwo = $('#inbenta-category-level2');
    const $catLevelThree = $('#inbenta-category-level3');
    const $moreResultsBlock = $('.inbenta-more-results');
    $elems.on('click', async (e) => {
      $elems.removeClass('active');
      const $this = $(e.target);
      const catId = $this.data('catId');
      const catName = $this.data('name');
      $(`li[data-cat-id=${catId}]`).addClass('active');
      const categoryContents = await inbentaApi.getCategoryContents(catId);
      const isEmptyCatContents = categoryContents?.results.length === 0;
      if (!isEmptyCatContents) {
        answerBlockUi(catName, categoryContents?.results, 'faq', true);
      }
      $moreResultsBlock.addClass('hide');
      if ($root.attr('id') === 'inbenta-category-level1') {
        $catLevelThree.html('');
        await categoriesBlockUi($catLevelTwo, catId, false, isEmptyCatContents);
      } else if ($root.attr('id') === 'inbenta-category-level2') {
        await categoriesBlockUi($catLevelThree, catId, false, isEmptyCatContents);
      }
    });
  }

  return (async function render() {
    const inbentaCategories = await inbentaApi.getCategories(100);
    const filtredCategories = filterCategories(inbentaCategories);
    categoryInThePath = findCategoryInThePath(filtredCategories);
    try {
      $root.slick('unslick');
    } catch (e) {
      Log.log(e);
    }

    $root.html(categoriesTemplate(filtredCategories));
    $root.slick({
      dots: false,
      draggable: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 2,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    const $elems = $root.find('li');
    eventInit($elems);
    if (idCat === 0) {
      categoryInThePath?.isInPath &&
        !byPassCatInThePath &&
        $root.find(`li[data-slug=${categoryInThePath?.name}]`).trigger('click');
      $('#inbenta-category-level2').html('');
      $('#inbenta-category-level3').html('');
    }
    if (triggerClick) {
      $elems.first().trigger('click');
    }

    return Promise.resolve(categoryInThePath);
  })();
}

(function categoriesHandler() {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');

  exportToGlobalSite({
    categoriesBlockUi,
  });
})();
